import axios from 'axios'
import { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';

let Users = () => {

    const [users, setUsers] = useState([])

    useEffect(() => {
        let APIENDPOINT = "http://localhost:8080/users";
        // APIENDPOINT = "http://10.0.2.2:8080/users";
        console.log("Is it online: ", navigator.onLine);
        if (navigator.onLine) {
            axios.get(APIENDPOINT)
                .then((response) => {
                    setUsers(response.data);
                    localStorage.setItem("users", JSON.stringify(response.data));
                })
                .catch((error) => {
                    let users = localStorage.getItem("users");
                    setUsers(JSON.parse(users));
                })
        } else {
            let users = localStorage.getItem("users");
            setUsers(JSON.parse(users));
        }
    }, [])

    return (
        <div>
            <h1>Users</h1>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Address</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        users.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>
                                    <td>{item.email}</td>
                                    <td>{item.address.street}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        </div>
    )

}

export default Users;