import About from './About';
import Home from './Home';
import Users from './Users';

import { Route, Routes, BrowserRouter as Router, Link } from 'react-router-dom'
import { Container, Nav, Navbar } from 'react-bootstrap';

function App() {
  return (
    <Container>
      <Router>
      <Navbar bg="primary" variant="dark">
        <Container>
        <Navbar.Brand>e-Customer</Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className='me-auto'>
            <Link className="nav-link" to="/">Home</Link>
            <Link className="nav-link" to="/about">About</Link>
            <Link className="nav-link" to="/users">Users</Link>
          </Nav>
        </Navbar.Collapse>
        </Container>
      </Navbar>
      <Routes>
        <Route exact path="/users" element={<Users/>}></Route>
        <Route exact path="/about" element={<About/>}></Route>
        <Route exact path="/" element={<Home/>}></Route>
      </Routes>
      </Router>
    </Container>
  );
}

export default App;
